import React, { useState, useEffect } from "react";
import axios from "axios";
import "./RaportPage.css"; // Import stylów
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import ReactLoading from "react-loading";

const RaportDownloader = ({ onClose }) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth() + 1; // getMonth() zwraca miesiąc od 0 do 11
  const [klienci, setKlienci] = useState([]);
  const [f_klient_id, setklient] = useState("");
  const [month, setMonth] = useState(
    currentMonth === 1 ? "12" : String(currentMonth - 1).padStart(2, "0")
  );
  const [year, setYear] = useState(
    currentMonth === 1 ? currentYear - 1 : currentYear
  );
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false); // Nowy stan dla spinnera

  const availableYears = [currentYear, currentYear - 1, currentYear - 2];

  const fetchKlienci = async () => {
    try {
      localStorage.setItem("lastActivity", new Date().toISOString());
      const response = await fetch("/api/klienci/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setKlienci(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  useEffect(() => {
    fetchKlienci();
  }, []);

  const handleDownload = async () => {
    if (!month || !year) {
      setError("Proszę wybrać zarówno miesiąc, jak i rok.");
      return;
    }

    setLoading(true); // Włącz spinner przed rozpoczęciem pobierania

    try {
      setError(null);
      let url = `/api/raporty_pdf/?month=${month}&year=${year}`;
      if (f_klient_id) {
        url += `&klient_id=${f_klient_id}`;
      }
      const response = await axios.get(url);

      const { zip_file } = response.data;

      if (!zip_file) {
        setError("Brak danych do pobrania.");
        setLoading(false); // Wyłącz spinner
        return;
      }

      const blob = new Blob(
        [Uint8Array.from(atob(zip_file), (c) => c.charCodeAt(0))],
        {
          type: "application/zip",
        }
      );

      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Raport_dla_firm_${month}_${year}.zip`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (err) {
      alert("Błąd podczas pobierania pliku:", err);
      setError("Wystąpił błąd podczas pobierania pliku.");
    } finally {
      setLoading(false); // Wyłącz spinner po zakończeniu
    }
  };

  return (
    <div className="raport">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Pobierz raport PDF</h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Miesiąc:
          <select
            value={month}
            onChange={(e) => setMonth(e.target.value)}
            style={{ margin: "0 10px" }}
          >
            <option value="">Wybierz miesiąc</option>
            <option value="01">Styczeń</option>
            <option value="02">Luty</option>
            <option value="03">Marzec</option>
            <option value="04">Kwiecień</option>
            <option value="05">Maj</option>
            <option value="06">Czerwiec</option>
            <option value="07">Lipiec</option>
            <option value="08">Sierpień</option>
            <option value="09">Wrzesień</option>
            <option value="10">Październik</option>
            <option value="11">Listopad</option>
            <option value="12">Grudzień</option>
          </select>
        </label>
        <label>
          Rok:
          <select
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{ margin: "0 10px" }}
          >
            <option value="">Wybierz rok</option>
            {availableYears.map((yr) => (
              <option key={yr} value={yr}>
                {yr}
              </option>
            ))}
          </select>
        </label>
      </div>
      <label>
        Klient:
        <select
          name="filter_klient_id"
          value={f_klient_id}
          onChange={(e) => setklient(e.target.value)}
        >
          <option value="">Wybierz klienta</option>
          {klienci.map((klient) => (
            <option key={klient.id} value={klient.id}>
              {klient.nazwa}
            </option>
          ))}
        </select>
      </label>

      {error && <p style={{ color: "red" }}>{error}</p>}

      {loading ? ( // Wyświetl spinner, jeśli `loading` jest true
        // <div className="loader-container">
        //   <h1>Trwa generowanie spakowanych zip raportów dla firm</h1>
          <ReactLoading type="spin" color="#00bfff" height={100} width={100} />
        // </div>
      ) : (
        <button onClick={handleDownload}>Pobierz raport</button>
      )}
    </div>
  );
};

export default RaportDownloader;
