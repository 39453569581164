import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { checkPermissions3 } from "./Permission";
import {
  faSignOutAlt,
  faKey,
  faUser,
  faTasks,
  faBuilding,
  faCogs,
  faFileArchive,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons"; // Import ikon
import "./Menu.css"; // Import stylów dla menu

const Menu = ({
  setCurrentPage,
  handleLogout,
  userData,
  handleChangePass,
  czasdo,
}) => {
  return (
    <nav className="menu">
      <div className="menu-items">
        <ul>
          <li onClick={() => setCurrentPage("prace")}>
            <FontAwesomeIcon icon={faTasks} /> Prace wykonane
          </li>
          <li onClick={() => setCurrentPage("firmy")}>
            <FontAwesomeIcon icon={faBuilding} /> Klienci
          </li>
          {checkPermissions3(3) && (
            <li onClick={() => setCurrentPage("user")}>
              <FontAwesomeIcon icon={faUser} /> Użytkownicy
            </li>
          )}
          {/* Sprawdzenie uprawnień - warunkowe renderowanie */}
          {checkPermissions3(7) && (
            <>
              <li onClick={() => setCurrentPage("zestf")}>
                <FontAwesomeIcon icon={faFileAlt} />
                <FontAwesomeIcon icon={faBuilding} /> Zestawienie klientów
              </li>
              <li onClick={() => setCurrentPage("zestu")}>
                <FontAwesomeIcon icon={faFileAlt} />
                <FontAwesomeIcon icon={faUser} /> Zestawienie użytkowników
              </li>
            </>
          )}

          {checkPermissions3(9) && (
            <>
              <li onClick={() => setCurrentPage("rfirm")}>
                <FontAwesomeIcon icon={faFileArchive} /> Raporty pdf dla
                klientów
              </li>
            </>
          )}

          {checkPermissions3(14) && (
            <>
              <li onClick={() => setCurrentPage("trwaja")}>
                <FontAwesomeIcon icon={faCogs} /> Prace w toku
              </li>
            </>
          )}
          {checkPermissions3(15) && (
            <>
              <li onClick={() => setCurrentPage("arch")}>
                <FontAwesomeIcon icon={faCogs} /> Archiwum zmian
              </li>
            </>
          )}
        </ul>
      </div>
      <div className="user-info">
        <div className="user-details">
          <p>{userData.name}&nbsp;</p>
          <div className="edit-icon" onClick={() => setCurrentPage("ccpasswd")}>
            <FontAwesomeIcon icon={faKey} />
          </div>
          &nbsp;
          <span className="logout" onClick={handleLogout}>
            <FontAwesomeIcon icon={faSignOutAlt} /> Wyloguj {userData.email}
            &nbsp; {czasdo}
          </span>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
