import React, { useState, useEffect } from "react";
import "./Archiwum.css"; // Import stylu

const Archiwum = () => {
  const [archiveData, setArchiveData] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchTerm, setSearchTerm] = useState(""); // Stan wyszukiwania
  const [page, setPage] = useState(1); // Stan paginacji
  const [totalPages, setTotalPages] = useState(1); // Stan całkowitej liczby stron
  const recordsPerPage = 5; // Liczba rekordów na stronę

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `/api/arch/?search=${searchTerm}&page=${page}&limit=${recordsPerPage}`
        );
        const data = await response.json();
        setArchiveData(data.results);
        setTotalPages(data.totalPages); // Zakładamy, że API zwraca totalPages
      } catch (error) {
        console.error("Błąd podczas pobierania danych:", error);
      }
    };
    fetchData();
  }, [searchTerm, page]);

  const handleClick = (record) => {
    setSelectedRecord(record);
  };

  const handleHideDetails = () => {
    setSelectedRecord(null); // Resetowanie stanu, aby ukryć szczegóły
  };

  const renderDetails = (record) => {
    const oldData = record.old_data ? JSON.parse(record.old_data) : {};
    const newData = record.new_data ? JSON.parse(record.new_data) : {};

    const keys = Object.keys(oldData);

    return (
      <table className="details-table">
        <thead>
          <tr>
            <th>Przed zmianą</th>
            <th>Po zmianie</th>
          </tr>
        </thead>
        <tbody>
          {keys.map((key) => {
            const isDifferent = oldData[key] !== newData[key]; // Sprawdzanie, czy wartości się różnią
            return (
              <tr key={key}>
                <td className={isDifferent ? "highlight" : ""}>
                  {key}: {oldData[key]}
                </td>
                <td className={isDifferent ? "highlight" : ""}>
                  {newData[key] !== undefined ? `${key}: ${newData[key]}` : ""}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  // Renderowanie przycisków paginacji
  const renderPaginationButtons = () => {
    const paginationButtons = [];
    const startPage = Math.max(1, page - 4); // Wyświetl 4 strony przed aktualną
    const endPage = Math.min(totalPages, page + 4); // Wyświetl 4 strony po aktualnej

    for (let i = startPage; i <= endPage; i++) {
      paginationButtons.push(
        <button
          key={i}
          className={i === page ? "active" : ""}
          onClick={() => setPage(i)}
        >
          {i}
        </button>
      );
    }

    return paginationButtons;
  };

  return (
    <div className="archive-page">
      <h1>Archiwalne zmiany danych w systemie</h1>

      {/* Pole wyszukiwania */}
      <input
        type="text"
        placeholder="Szukaj..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>Tablica</th>
            <th>Record ID</th>
            <th>Zmianiający</th>
            <th>Typ zmiany</th>
            <th>Data i czas</th>
            <th>Akcja</th>
          </tr>
        </thead>
        <tbody>
          {archiveData.map((record) => (
            <tr key={record.id}>
              <td>{record.table_name}</td>
              <td>{record.record_id}</td>
              <td>{record.changed_by}</td>
              <td>{record.change_type}</td>
              <td>{record.created_at}</td>
              <td>
                <button
                  className="show-details"
                  onClick={() => handleClick(record)}
                >
                  Pokaż szczegóły
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRecord && (
        <div className="details-container">
          <div className="details-header">
            <h2>Szczegóły dla rekordu zmiany ID: {selectedRecord.record_id}</h2>
            <button className="hide-details-small" onClick={handleHideDetails}>
              Ukryj szczegóły
            </button>
          </div>
          {renderDetails(selectedRecord)}
        </div>
      )}

      {/* Paginacja */}
      <div className="pagination-controls">
        {page > 1 && <button onClick={() => setPage(1)}>Pierwsza</button>}

        {page > 4 && <span>...</span>}

        {renderPaginationButtons()}

        {page < totalPages - 3 && <span>...</span>}

        {page < totalPages && (
          <button onClick={() => setPage(totalPages)}>Ostatnia</button>
        )}
      </div>
    </div>
  );
};

export default Archiwum;
