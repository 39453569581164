import Swal from "sweetalert2"; // Importuj SweetAlert

// Funkcja sprawdzająca uprawnienia
export const checkPermissions2 = (index) => {
  // Pobierz dane użytkownika z localStorage
  const storedUser = localStorage.getItem("loggedUser");

  if (storedUser) {
    const parsedUser = JSON.parse(storedUser); // Parsowanie JSON do obiektu
    const uprawnienia = parsedUser.uprawnienia; // Odczytaj uprawnienia

    // Sprawdź, czy użytkownik ma uprawnienia na danym indeksie
    if (uprawnienia && uprawnienia[index] === "1") {
      return true;
    } else {
      Swal.fire({
        icon: "error",
        title: "Brak uprawnień",
        text: "Nie masz odpowiednich uprawnień, aby uzyskać dostęp do tej strony.",
        confirmButtonText: "OK",
      });
      return false;
    }
  } else {
    // Jeśli brak danych użytkownika w localStorage
    Swal.fire({
      icon: "error",
      title: "Błąd",
      text: "Nie udało się pobrać danych użytkownika. Zaloguj się ponownie.",
      confirmButtonText: "OK",
    });
    return false;
  }
};

export const checkPermissions3 = (index) => {
  // Pobierz dane użytkownika z localStorage
  const storedUser = localStorage.getItem("loggedUser");

  if (storedUser) {
    const parsedUser = JSON.parse(storedUser); // Parsowanie JSON do obiektu
    const uprawnienia = parsedUser.uprawnienia; // Odczytaj uprawnienia

    // Sprawdź, czy użytkownik ma uprawnienia na danym indeksie
    if (uprawnienia && uprawnienia[index] === "1") {
      return true;
    }
  } else {
    // Jeśli brak danych użytkownika w localStorage
    Swal.fire({
      icon: "error",
      title: "Błąd",
      text: "Nie udało się pobrać danych użytkownika. Zaloguj się ponownie.",
      confirmButtonText: "OK",
    });
    return false;
  }
};

export const getuser3 = () => {
  // Pobierz dane użytkownika z localStorage
  const storedUser = localStorage.getItem("loggedUser");

  if (storedUser) {
    const parsedUser = JSON.parse(storedUser); // Parsowanie JSON do obiektu

    // Sprawdź, czy użytkownik ma uprawnienia na danym indeksie
    if (parsedUser) {
      return parsedUser.email;
    }
  } else {
    // Jeśli brak danych użytkownika w localStorage
    Swal.fire({
      icon: "error",
      title: "Błąd",
      text: "Nie udało się pobrać danych użytkownika. Zaloguj się ponownie.",
      confirmButtonText: "OK",
    });
    return "";
  }
};
