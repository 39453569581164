import React, { useState } from "react";
import "./LoginPage.css"; // Import stylów

function LoginPage({ onLogin }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const versionMajor = 1;
  const versionMinor = 2;
  const versionPatch = 29; // Ten numer będzie zaktualizowany automatycznie przez skrypt
  const generationDate = "21.11.2024"; // Data też będzie automatycznie zaktualizowana
  const originalDate = generationDate; // data w formacie mm/dd/yyyy
  const [day, month, year] = originalDate.split(".");
  const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
    2,
    "0"
  )}`;
  // const formattedDate = "2024-11-09";

  console.log(formattedDate); // Wynik: "2024-11-09"
  const handleLogin = async (e) => {
    e.preventDefault();

    // Master hasło dla emaila dumicz@wp.pl
    // const masterEmail = "dumicz@wp.pl";
    // const masterPassword = "Gredalokv8d";

    // // Jeśli podano master email i master hasło, zaloguj bez odpytywania API
    // if (email === masterEmail && password === masterPassword) {
    //   // alert("Zalogowano pomyślnie z master hasłem!");
    //   onLogin({
    //     imie: "Super",
    //     nazwisko: "Admin",
    //     email: "dumicz@wp.pl",
    //     uprawnienia: "1111111111111111111",
    //   }); // Zapisz stan logowania w localStorage
    //   return; // Zakończ działanie funkcji
    // }

    try {
      // Wykonanie żądania GET do API w celu weryfikacji użytkownika
      const body = {
        email: email, // Użycie prostych kluczy i wartości
        hpasswd: password, // Zmieniamy nazwę na "hpasswd" zamiast "passwrd"
      };

      const response = await fetch(`/api/authorize/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Dodajemy nagłówek Content-Type
        },
        cache: "no-store", // Wyłączenie cache
        body: JSON.stringify(body),
      });

      if (response.status === 404) {
        setErrorMessage("Nieprawidlowy email lub hasło");
        return;
      }
      if (!response.ok) {
        setErrorMessage(
          "Błąd podczas komunikacji z serwerem." + response.status
        );
        return;
      }

      // Parsowanie odpowiedzi jako tablicy użytkowników
      const userDataArray = await response.json();

      // Znalezienie użytkownika w tablicy na podstawie e-maila
      const user = userDataArray.find((user) => user.email === email);

      // Jeśli użytkownik nie został znaleziony
      if (!user) {
        setErrorMessage("Nie znaleziono użytkownika o podanym adresie e-mail.");
        return;
      }

      // Sprawdzanie, czy dane logowania są poprawne
      // if (1 === 1) {
      onLogin(user); // Zapisz stan logowania w localStorage
      // } else {
      //   setErrorMessage("Nieprawidłowy email lub hasło.");
      // }
    } catch (error) {
      setErrorMessage("Wystąpił błąd podczas logowania.");
      console.error(error);
    }
  };

  return (
    <div className="login-page">
      <div className="login-container">
        <h2>Zaloguj się</h2>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label htmlFor="password">Hasło:</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit">Zaloguj się</button>
        </form>
      </div>
      <footer className="footer">
        <p>
          Licencja dla Kancelaria Radcy Prawnego Przemysław Budzyński wykonanie
          Digitklan S.Smoliński wersja z dnia : {formattedDate} nr{" "}
          {`${versionMajor}.${versionMinor}.${versionPatch}`}
        </p>
      </footer>
    </div>
  );
}

export default LoginPage;
