import React, { useState, useEffect, useCallback } from "react";
import Swal from "sweetalert2";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./font/Roboto-Regular-normal";

import "./ZestawienieFirmPage.css"; // Użyjemy tego samego pliku CSS dla zachowania stylu

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import {
  faFilePdf,
  faCalendarAlt,
  faCalendarMinus,
  faCalendarCheck,
  faDownload,
} from "@fortawesome/free-solid-svg-icons"; // Import ikon
import { checkPermissions3 } from "./Permission";
// import { addFont } from "./Roboto-Regular-normal.js";
const ZestawieniePracownikowPage = ({ loggedUser, onClose }) => {
  const [zestawienie, setZestawienie] = useState([]);
  const [filterType, setFilterType] = useState("C"); // Domyślnie cały okres
  const [filterUserOnly, setFilterUserOnly] = useState(
    () => !checkPermissions3(8)
  );

  const getDateBefore180Days = () => {
    const today = new Date();
    today.setDate(today.getDate() - 180);
    return today.toISOString().split("T")[0]; // Zwracamy w formacie yyyy-mm-dd
  };

  const getDateAfter90Days = () => {
    const today = new Date();
    today.setDate(today.getDate() + 90);
    return today.toISOString().split("T")[0]; // Zwracamy w formacie yyyy-mm-dd
  };

  const [formData, setFormData] = useState({
    data_od: getDateBefore180Days(), // Domyślna wartość sprzed 180 dni
    //data_do: new Date().toISOString().split("T")[0], // Domyślna dzisiejsza data
    data_do: getDateAfter90Days(), // Domyślna dzisiejsza data
  });
  const [isFullPeriod, setIsFullPeriod] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchZestawienie = useCallback(async () => {
    setIsLoading(true);
    try {
      const dateFilter =
        formData.data_od && formData.data_do
          ? `&data_odd=${formData.data_od}&data_doo=${formData.data_do}`
          : "";
      const userFilter = filterUserOnly
        ? `&uzytkownik=${loggedUser.email}`
        : "";
      const response = await fetch(
        `/api/ZestaPracownik/?co=${filterType}${dateFilter}${userFilter}`,
        {
          method: "GET",
          cache: "no-store",
        }
      );

      const data = await response.json();
      setZestawienie(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Błąd podczas pobierania danych.",
      });
    } finally {
      setIsLoading(false);
    }
  }, [filterType, formData, filterUserOnly, loggedUser.email]);

  useEffect(() => {
    // if (checkPermissions3(8)) {
    //   setFilterUserOnly(false);
    // } else {
    //   setFilterUserOnly(true);
    // }
    fetchZestawienie();
  }, [filterType, fetchZestawienie]);

  const setCurrentMonth = () => {
    setFilterType("M");
    setIsFullPeriod(false);
  };

  const setPreviousMonth = () => {
    setFilterType("P");
    setIsFullPeriod(false);
  };

  const setFullPeriod = () => {
    setFilterType("C");
    setIsFullPeriod(true);
  };

  const handleDateChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Funkcja do formatowania wartości w formacie 9,999.99
  const formatValue = (value, poo) => {
    // Konwertuj wartość na liczbę, jeśli to konieczne
    const numberValue = typeof value === "number" ? value : parseFloat(value);

    // Sprawdzenie, czy liczba jest prawidłowa
    if (isNaN(numberValue)) {
      return value; // Jeśli wartość nie jest liczbą, zwróć ją bez zmian
    }

    // Formatowanie ręczne
    return numberValue
      .toFixed(poo) // Zachowaj dwie cyfry po przecinku
      .replace(/\d(?=(\d{3})+\.)/g, "$& ") // Dodaj spacje co trzy cyfry przed kropką (separatorem dziesiętnym)
      .replace(".", ","); // Zamień kropkę na przecinek
  };

  // Obliczanie sum godzin i wartości
  const calculateTotals = (zestawienie) => {
    const totalGodziny = zestawienie.reduce(
      (acc, pracownik) => acc + pracownik.ilosc_godz,
      0
    );
    const totalWartosc = zestawienie.reduce(
      (acc, pracownik) => acc + (pracownik.Wartosc || 0),
      0
    );
    return { totalGodziny, totalWartosc };
  };

  const { totalGodziny, totalWartosc } = calculateTotals(zestawienie);
  const formatDate = (date) => date.toISOString().split("T")[0];
  // Funkcja do generowania PDF
  const generatePDF = () => {
    const input = document.getElementById("zestawienie-table"); // Pobieramy element tabeli
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      const imgWidth = 210; // A4 width in mm
      const pageHeight = 297; // A4 height in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 30;
      let data_odd, data_doo, subtitle;
      const today = new Date();
      pdf.setFont("Roboto-Regular", "normal");
      const title = "Kancelaria Radcy Prawnego Przemysław Budzyński";
      if (filterType === "M") {
        // Bieżący miesiąc
        // Pierwszy dzień bieżącego miesiąca z godziną 12:00
        data_odd = new Date(today.getFullYear(), today.getMonth(), 1, 12, 0, 0);
        // Ostatni dzień bieżącego miesiąca - ustawiamy na godzinę 23:59:59 dnia ostatniego
        data_doo = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1,
          0,
          0,
          0
        );
        data_doo.setSeconds(data_doo.getSeconds() - 1); // Cofamy o 1 sekundę, aby uzyskać ostatnią sekundę bieżącego miesiąca
      } else if (filterType === "P") {
        // Poprzedni miesiąc
        // Pierwszy dzień poprzedniego miesiąca z godziną 12:00
        data_odd = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1,
          12,
          0,
          0
        );
        // Ostatni dzień poprzedniego miesiąca - ustawiamy na godzinę 23:59:59 dnia ostatniego
        data_doo = new Date(today.getFullYear(), today.getMonth(), 1, 0, 0, 0);
        data_doo.setSeconds(data_doo.getSeconds() - 1);
      } else if (filterType === "C") {
        // Ostatnie 180 dni
        data_odd = new Date(today);
        data_odd.setDate(today.getDate() - 180); // Odejmujemy 180 dni od dzisiejszej daty
        data_doo = today; // Dzisiaj
      } else {
        console.log("Nieprawidłowy typ filtra");
        return;
      }
      data_doo = formatDate(data_doo);
      data_odd = formatDate(data_odd);
      if (filterType === "M") {
        subtitle = `Zestawienie użytkownikow  od ${data_odd} do ${data_doo}`;
      } else if (filterType === "P") {
        subtitle = `Zestawienie użytkownikow  za okres od ${data_odd} do ${data_doo}`;
      } else if (filterType === "C") {
        subtitle = `Zestawienie użytkownikow  za okres od ${formData.data_od} do ${formData.data_do}`;
      }

      // const subtitle = `Zestawienie użytkownikow za okres od ${formData.data_od} do ${formData.data_do}`;

      // Ustawienia czcionki

      pdf.setFontSize(14); // Ustawiamy większy rozmiar czcionki dla tytułu
      pdf.text(title, 10, 10); // Tytuł na górze strony
      pdf.setFontSize(12); // Mniejszy rozmiar czcionki dla podtytułu
      pdf.text(subtitle, 10, 20); // Podtytuł poniżej tytułu
      pdf.setFont("Arial", "normal"); // Ustawienie Arial jako fontu
      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.setFont("Roboto-Regular", "normal");
      const title2 = "System Kancelaria Digitklan Sebastian Smolński (2024) ";

      // Ustawienia czcionki

      pdf.setFontSize(7); // Ustawiamy większy rozmiar czcionki dla tytułu
      pdf.text(title2, 110, 290); // Podtytuł poniżej tytułu
      pdf.save("zestawienie_uzytkowników.pdf"); // Zapisz plik PDF
    });
  };

  return (
    <div className="prace-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Zestawienie użytkowników</h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>

      <div className="filter-buttons">
        <button className="add-praca" onClick={setCurrentMonth}>
          <FontAwesomeIcon
            icon={faCalendarAlt}
            style={{ marginRight: "8px" }}
          />
          Bieżący miesiąc
        </button>
        <button className="add-praca" onClick={setPreviousMonth}>
          <FontAwesomeIcon
            icon={faCalendarMinus}
            style={{ marginRight: "8px" }}
          />
          Poprzedni miesiąc
        </button>
        <button className="add-praca" onClick={setFullPeriod}>
          <FontAwesomeIcon
            icon={faCalendarCheck}
            style={{ marginRight: "8px" }}
          />
          Cały okres
        </button>
        <button className="add-praca" onClick={generatePDF}>
          <FontAwesomeIcon icon={faDownload} style={{ marginRight: "8px" }} />
          <FontAwesomeIcon icon={faFilePdf} style={{ marginRight: "8px" }} />
          Pobierz PDF
        </button>
      </div>
      {checkPermissions3(8) && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "20px",
          }}
        >
          <input
            type="checkbox"
            checked={filterUserOnly}
            onChange={(e) => setFilterUserOnly(e.target.checked)}
            style={{ width: "30px", height: "30px", marginRight: "10px" }} // Powiększenie przycisku i dodanie odstępu
          />
          <label style={{ fontSize: "20px" }}>Tylko moje prace</label>
        </div>
      )}
      {isFullPeriod && (
        <div className="additional-filters">
          <label>
            Data od:
            <input
              type="date"
              name="data_od"
              value={formData.data_od}
              onChange={handleDateChange}
            />
          </label>
          <label>
            Data do:
            <input
              type="date"
              name="data_do"
              value={formData.data_do}
              onChange={handleDateChange}
            />
          </label>
          <button className="add-praca" onClick={fetchZestawienie}>
            Filtruj
          </button>
        </div>
      )}
      {isLoading ? (
        <p>Ładowanie danych...</p>
      ) : (
        <div>
          <table id="zestawienie-table">
            <thead>
              <tr>
                <th>Użytkownik</th>
                <th className="text-right">Godzin</th>
                <th className="text-right">Prac</th>
                <th>Data od</th>
                <th>Data do</th>
                <th className="text-right">Wartość</th>
              </tr>
            </thead>
            <tbody>
              {zestawienie.map((pracownik, index) => (
                <tr key={index}>
                  <td>{pracownik.Pracownik}</td>
                  <td className="text-right">
                    {pracownik.ilosc_godz !== undefined
                      ? formatValue(pracownik.ilosc_godz, 1)
                      : "0.0"}
                  </td>
                  <td className="text-right">
                    {pracownik.ilosc_prac !== undefined
                      ? formatValue(pracownik.ilosc_prac, 0)
                      : "0"}
                  </td>
                  <td>{pracownik.min_data_od}</td>
                  <td>{pracownik.max_data_do}</td>
                  <td className="text-right">
                    {pracownik.Wartosc !== undefined
                      ? formatValue(pracownik.Wartosc, 2)
                      : "0.00"}
                  </td>
                </tr>
              ))}
              {/* Dodajemy wiersz podsumowujący */}
              <tr>
                <td>
                  <strong>Podsumowanie</strong>
                </td>
                <td className="text-right">
                  <strong>{formatValue(totalGodziny, 1)}</strong>
                </td>
                <td className="text-right"></td>
                <td></td>
                <td></td>
                <td className="text-right">
                  <strong>{formatValue(totalWartosc, 2)}</strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ZestawieniePracownikowPage;
