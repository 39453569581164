import React, { useState, useEffect } from "react";
import "./TrwajaPage.css"; // Import stylizacji
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faTimes, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import PracaEdit from "./ModalPrace"; // Import komponentu edycji
import ClipLoader from "react-spinners/ClipLoader"; // Import loadera
import { checkPermissions2, getuser3 } from "./Permission";
import Swal from "sweetalert2";
const TrwajaPage = ({ onClose, loggedUser }) => {
  const [prace, setPrace] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [pracePoUzytkownikach, setGrupa] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(false); // Stan dla modalnego okna edycji
  const [selectedPraca, setSelectedPraca] = useState(null); // Przechowuje wybraną pracę do edycji
  const uzyt_id = getuser3();

  // Funkcja pobierająca dane z API

  const fetchPrace = async () => {
    try {
      setLoading(true); // Ustawiamy loader na true przed rozpoczęciem pobierania danych
      const response = await fetch(`/api/prace/?co=T`);
      const data = await response.json();
      if (Array.isArray(data.prace)) {
        setPrace(data.prace);
      } else {
        setPrace([]);
      }
      setLoading(false); // Ustawiamy loader na false po zakończeniu pobierania danych
    } catch (error) {
      console.error("Błąd podczas pobierania danych:", error);
      setError("Błąd podczas pobierania danych");
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrace();
  }, []);

  // Funkcja grupująca prace według użytkowników
  const grupujPracePoUzytkownikach = (prace) => {
    return prace.reduce((grouped, praca) => {
      const { uzytkownik_id } = praca;
      if (!grouped[uzytkownik_id]) {
        grouped[uzytkownik_id] = [];
      }
      grouped[uzytkownik_id].push(praca);
      return grouped;
    }, {});
  };

  const handleEditPraca = (praca) => {
    setSelectedPraca(praca); // Ustaw wybraną pracę do edycji
    setIsEditOpen(true); // Otwórz modal
  };

  const handleDeletePraca = (id) => {
    if (checkPermissions2(1)) {
      Swal.fire({
        title: "Czy na pewno chcesz usunąć pracę?",
        text: "Tego działania nie można cofnąć!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`/api/prace/${id}?uzyt_id=${uzyt_id}`, { method: "DELETE" })
            .then((response) => {
              if (response.ok) {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Praca została usunięta!",
                });
                fetchPrace();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: "Błąd podczas usuwania pracy",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Wystąpił problem podczas usuwania pracy.",
              });
            });
        }
      });
    }
  };

  const handleSave = () => {
    setIsEditOpen(false); // Zamknij modal po zapisaniu
    fetchPrace(); // Odśwież listę prac
    // setGrupa(grupujPracePoUzytkownikach(prace));
  };

  const handleCancel = () => {
    setIsEditOpen(false); // Zamknij modal po anulowaniu
  };

  if (loading) {
    return (
      <div className="loader-container">
        <ClipLoader size={50} color={"#123abc"} loading={loading} />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  const pracePoUzytkownikach = grupujPracePoUzytkownikach(prace);

  return (
    <div className="prace-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>W toku według użytkowników</h1>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>

      <div className="cards-grid">
        {Object.keys(pracePoUzytkownikach).map((uzytkownik_id) => (
          <div key={uzytkownik_id} className="user-card">
            <div className="card-header">
              <h2>Dane użytkownika: {uzytkownik_id}</h2>
            </div>
            <div className="card-body">
              <ul>
                {pracePoUzytkownikach[uzytkownik_id].map((praca) => (
                  <li key={praca.id} className="work-item">
                    <strong>Nazwa firmy:</strong> {praca.klient_nazwa} <br />
                    {/* <strong>Data od:</strong> {praca.id} <br /> */}
                    <strong>Termin do:</strong> {praca.datatdo} <br />
                    <strong>Opis:</strong> {praca.opis} <br />
                    {praca.uzytkownik2 !== null && praca.uzytkownik2 !== "" && (
                      <>
                        <strong>Współautor:</strong> {praca.uzytkownik2} <br />
                      </>
                    )}
                    <strong>Liczba godzin:</strong> {praca.ile_czasu}
                    <div className="action-buttons">
                      {/* Przycisk edycji */}
                      <button
                        className="edit-btn"
                        onClick={() => handleEditPraca(praca)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </button>
                      {/* Przycisk usuwania */}
                      <button
                        className="delete-btn"
                        onClick={() => handleDeletePraca(praca.id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      {/* Modal do edycji pracy */}
      {isEditOpen && (
        <PracaEdit
          idPraca={selectedPraca.id}
          isEditing={true}
          loggedUser={loggedUser}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );
};

export default TrwajaPage;
