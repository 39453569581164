import React from "react";
import "./ModalUser.css"; // Styl dla modal

const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content3">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/*  <button className="modal-close" onClick={onClose}>
            &times;
          </button> */}
        </div>
        {children}{" "}
        {/* Przycisk "Zapisz zmiany" jest już w formularzu, więc nie ruszamy go tutaj */}
      </div>
    </div>
  );
};

export default Modal;
