import React, { useState, useEffect } from "react";
import LoginPage from "./LoginPage"; // Import strony logowania
import Menu from "./Menu"; // Import menu po zalogowaniu
import UserPage from "./UserPage"; // Import strony użytkowników (CRUD)
import KlienciPage from "./KlienciPage"; // Import strony klientów (CRUD)
import PracePage from "./PracePage"; // Import strony prace (CRUD)
import ZestawienieFirmPage from "./ZestawienieFirmPage";
import ZestawieniePracownikowPage from "./ZestawieniePracownikowPage";
import ChangePassword from "./ChangePassword";
import TrwajaPage from "./TrwajaPage";
import Archiwum from "./Archiwum";
import PracaEdit from "./ModalPrace";
import RaportDownloader from "./RaportPage";
import Swal from "sweetalert2"; // Import Swal

const MAX_INACTIVITY_TIME = 30 * 60 * 1000; // 2 godziny w milisekundach
// const MAX_INACTIVITY_TIME = 2 * 60 * 60 * 1000; // 2 godziny w milisekundach

function App() {
  // Sprawdzanie, czy użytkownik jest zalogowany na podstawie localStorage
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem("isLoggedIn") === "true"
  );

  // Pobieranie danych zalogowanego użytkownika z localStorage
  const [loggedUser, setLoggedUser] = useState(() => {
    const storedUser = localStorage.getItem("loggedUser");
    return storedUser
      ? JSON.parse(storedUser)
      : {
          name: "",
          email: "",
          uprawnienia: "",
        };
  });

  const [currentPage, setCurrentPage] = useState(" ");

  // const onCloseChange = () => {
  //   setCurrentPage("");
  // };

  const [timeLeft, setTimeLeft] = useState(MAX_INACTIVITY_TIME); // Stan na pozostały czas do wylogowania

  // Funkcja do zalogowania użytkownika
  const handleLogin = (Dane) => {
    setIsLoggedIn(true);

    localStorage.setItem("isLoggedIn", "true"); // Zapisanie stanu zalogowania

    const userData = {
      name: Dane.imie + " " + Dane.nazwisko, // Zastąp odpowiednimi danymi użytkownika po zalogowaniu
      email: Dane.email,
      uprawnienia: Dane.uprawnienia, // Możesz pobierać te dane z formularza logowania
    };

    localStorage.setItem("loggedUser", JSON.stringify(userData)); // Zapisanie danych użytkownika w localStorage
    localStorage.setItem("lastActivity", new Date().toISOString()); // Zapisanie daty ostatniej aktywności
    setLoggedUser(userData); // Aktualizacja stanu aplikacji
    setTimeLeft(MAX_INACTIVITY_TIME); // Resetuj czas po zalogowaniu
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn"); // Usunięcie stanu zalogowania
    localStorage.removeItem("loggedUser"); // Usunięcie danych użytkownika
    localStorage.removeItem("lastActivity"); // Usunięcie daty ostatniej aktywności
    setLoggedUser({
      name: "",
      email: "",
      uprawnienia: "",
    }); // Resetowanie danych użytkownika w stanie aplikacji
    setTimeLeft(MAX_INACTIVITY_TIME); // Resetuj czas po wylogowaniu
  };

  const checkPermissions = (index, { zerujpage }) => {
    if (loggedUser.uprawnienia && loggedUser.uprawnienia[index] === "1") {
      return true;
    } else {
      setCurrentPage("");
      localStorage.setItem("lastActivity", new Date().toISOString());
      Swal.fire({
        icon: "error",
        title: "Brak uprawnień",
        text: "Nie masz odpowiednich uprawnień, aby uzyskać dostęp do tej strony.",
        confirmButtonText: "OK",
      });
      return false;
    }
  };

  // Funkcja do sprawdzania czasu nieaktywności i aktualizacji czasu pozostałego do wylogowania
  const checkInactivity = () => {
    const lastActivity = localStorage.getItem("lastActivity");
    if (lastActivity) {
      const now = new Date();
      const lastActivityTime = new Date(lastActivity);
      const timeDiff = now - lastActivityTime;
      const timeRemaining = MAX_INACTIVITY_TIME - timeDiff;

      if (timeRemaining <= 0) {
        // Jeśli użytkownik był nieaktywny dłużej niż 2 godziny, wyloguj go
        handleLogout();
        Swal.fire({
          icon: "warning",
          title: "Zostałeś wylogowany!",
          text: "Zostałeś wylogowany z powodu braku aktywności przez pół godziny.",
          confirmButtonText: "OK",
        });
      } else {
        // Aktualizuj czas pozostały do wylogowania
        setTimeLeft(timeRemaining);
      }
    }
  };

  // Przy każdej zmianie strony (currentPage) lub akcji użytkownika, sprawdzamy czas nieaktywności
  useEffect(() => {
    if (isLoggedIn) {
      const interval = setInterval(() => {
        checkInactivity();
      }, 1000); // Aktualizujemy co sekundę
      return () => clearInterval(interval); // Czyszczenie interwału po odmontowaniu
    }
  });

  // const Close = () => {
  //   alert("tu");
  // };

  // Funkcja formatowania czasu
  const formatTimeLeft = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${hours}h ${minutes}m ${seconds}s`;
  };

  // Ustawienie tła na całą stronę
  useEffect(() => {
    document.body.style.backgroundImage = 'url("/tlomain2.jpg")';
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundAttachment = "fixed";
    document.body.classList.add("overlay");
  }, []);

  return (
    <div className="App">
      {isLoggedIn ? (
        <>
          <Menu
            setCurrentPage={setCurrentPage}
            handleLogout={handleLogout}
            userData={loggedUser} // Przekazywanie danych użytkownika do Menu
            czasdo={formatTimeLeft(timeLeft)}
          />
          <div className="content">
            {currentPage === "pusta" && <div></div>} {/* Pusta strona */}
            {currentPage === "user" &&
              checkPermissions(3, { setCurrentPage }) && (
                <UserPage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}{" "}
            {currentPage === "firmy" &&
              checkPermissions(5, { setCurrentPage }) && (
                <KlienciPage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}{" "}
            {currentPage === "zestf" &&
              checkPermissions(7, { setCurrentPage }) && (
                <ZestawienieFirmPage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}{" "}
            {currentPage === "zestu" &&
              checkPermissions(7, { setCurrentPage }) && (
                <ZestawieniePracownikowPage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}{" "}
            {currentPage === "prace" &&
              checkPermissions(0, { setCurrentPage }) && (
                <PracePage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}
            {currentPage === "trwaja" &&
              checkPermissions(14, { setCurrentPage }) && (
                <TrwajaPage
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}
            {currentPage === "rfirm" &&
              checkPermissions(9, { setCurrentPage }) && (
                <RaportDownloader
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}
            {currentPage === "arch" &&
              checkPermissions(15, { setCurrentPage }) && (
                <Archiwum
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )}
            {/* {currentPage === "arch" &&
              checkPermissions(15, { setCurrentPage }) && (
                <PracaEdit
                  idPraca={3259}
                  isEditing={true}
                  loggedUser={loggedUser}
                  onClose={() => setCurrentPage(" ")}
                />
              )} */}
            {currentPage === "ccpasswd" && (
              <ChangePassword
                onClose={() => setCurrentPage(" ")}
                logout={handleLogout} // Poprawiona nazwa
                loggedUser={loggedUser}
              />
            )}
          </div>
        </>
      ) : (
        <LoginPage onLogin={handleLogin} />
      )}
    </div>
  );
}

export default App;
