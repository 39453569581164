import React, { useEffect } from "react";
import Swal from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faKey, faPowerOff, faTimes } from "@fortawesome/free-solid-svg-icons"; // Import ikon

function ChangePassword({ onClose, logout, loggedUser }) {
  const handlePasswordChangeSubmit = async (oldPassword, newPassword) => {
    try {
      // Wysyłanie zapytania POST do endpointu /api/changepasswd/
      const response = await fetch(`/api/changepasswd/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: loggedUser.email, // Adres e-mail pobierany z loggedUser
          hpasswd: oldPassword, // Stare hasło
          npasswd: newPassword, // Nowe hasło
        }),
      });

      if (response.ok) {
        Swal.fire({
          title: "Sukces!",
          text: "Hasło zostało zmienione.",
          icon: "success",
          timer: 3000,
          showCancelButton: false,
          showConfirmButton: false,
        });

        setTimeout(onClose, 3000); // Zamknięcie po sukcesie
      } else {
        Swal.fire({
          title: "Błąd!",
          text: "Wystąpił błąd podczas zmiany hasła.",
          icon: "error",
        });
      }
    } catch (error) {
      console.error("Błąd podczas zmiany hasła:", error);
      Swal.fire({
        title: "Błąd!",
        text: "Wystąpił błąd podczas zmiany hasła.",
        icon: "error",
      });
    }
  };

  const showModal = () => {
    Swal.fire({
      title: `Zmień hasło`,
      html: `
        <input id="oldPassword" class="swal2-input" placeholder="Stare hasło" type="password">
        <input id="newPassword" class="swal2-input" placeholder="Nowe hasło" type="password">
        <input id="confirmNewPassword" class="swal2-input" placeholder="Potwierdź nowe hasło" type="password">
      `,
      focusConfirm: false,
      preConfirm: () => {
        const oldPassword = document.getElementById("oldPassword").value;
        const newPassword = document.getElementById("newPassword").value;
        const confirmNewPassword =
          document.getElementById("confirmNewPassword").value;

        if (newPassword !== confirmNewPassword) {
          Swal.showValidationMessage("Hasła muszą być jednakowe.");
          return false;
        }

        handlePasswordChangeSubmit(oldPassword, newPassword); // Wywołanie zmiany hasła
      },
      showCancelButton: true,
    });
  };

  // Hook useEffect wywołuje showModal od razu po zamontowaniu komponentu
  useEffect(() => {
    showModal(); // Automatyczne wywołanie modala po załadowaniu komponentu
  }, []);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <FontAwesomeIcon
          icon={faTimes} // Ikona zamknięcia
          onClick={onClose} // Funkcja zamykająca
          style={{ cursor: "pointer", fontSize: "20px" }} // Styl ikony zamknięcia
        />
      </div>
      <div>
        <ul style={{ listStyleType: "none", padding: 0, display: "flex" }}>
          <li
            onClick={showModal}
            style={{ marginRight: "10px", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faKey} title="Zmień hasło" />
          </li>
          <li onClick={logout} style={{ cursor: "pointer" }}>
            <FontAwesomeIcon icon={faPowerOff} title="Wyloguj" />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ChangePassword;
