import React, { useState, useEffect } from "react";
import Modal from "./ModalKlienci"; // Import okna modalnego
import Swal from "sweetalert2"; // Import Swal
import { checkPermissions3, getuser3 } from "./Permission";
import {
  faPlus,
  faEdit,
  faTrash,
  faSync,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import Font Awesome

import "./KlienciPage.css"; // Import stylu dla KlienciPage
import { checkPermissions2 } from "./Permission";

import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import ikon
const KlienciPage = ({ onClose }) => {
  const [clients, setClients] = useState([]);
  const [filteredClients, setFilteredClients] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(15); // Liczba klientów na stronę
  const [searchTerm, setSearchTerm] = useState(""); // Nowe pole wyszukiwania
  const [formData, setFormData] = useState({
    nazwa: "",
    ulica: "",
    nr_domu: "",
    kod_pocztowy: "",
    miasto: "",
    nip: "",
    regon: "",
    krs: "",
    email: "",
    telefon: "",
    notatki: "",
    opisroz: "",
    plikwzor: "",
    cenarg: 0.0, // Dodano cenarg jako część stanu
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showCenarg] = useState(false); // Stan dla wyświetlania cenarg
  const uzyt_id = getuser3();
  // alert(uzyt_id);
  // Funkcja do pobierania klientów z API
  const fetchClients = async () => {
    try {
      localStorage.setItem("lastActivity", new Date().toISOString());
      const response = await fetch("/api/klienci/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setClients(data);
      setFilteredClients(data); // Zainicjalizuj listę filtrowanych klientów
    } catch (error) {
      console.error("Błąd pobierania danych:", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleEditClient = (client) => {
    if (checkPermissions2(6)) {
      setIsEditing(true);
      setFormData({ ...client });
      setIsModalOpen(true);
    }
  };

  const handleAddClient = () => {
    if (checkPermissions2(6)) {
      setIsEditing(false);
      setFormData({
        nazwa: "",
        ulica: "",
        nr_domu: "",
        kod_pocztowy: "",
        miasto: "",
        nip: "",
        regon: "",
        krs: "",
        email: "",
        telefon: "",
        notatki: "",
        opisroz: "",
        plikwzor: "",
        cenarg: 0.0, // Resetowanie pola cenarg
      });
      setIsModalOpen(true);
    }
  };

  const checkClientExists = (nip) => {
    return clients.some((client) => client.nip === nip);
  };

  const handleAddNewClient = async (e) => {
    e.preventDefault();

    // Sprawdź, czy klient z danym NIP-em już istnieje
    if (checkClientExists(formData.nip)) {
      Swal.fire({
        icon: "error",
        title: "Błąd",
        text: "Klient z podanym NIP-em już istnieje!",
      });
      return;
    }

    try {
      // alert(JSON.stringify(formData));

      const response = await fetch(`/api/klienci/?uzyt_id=${uzyt_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Sukces",
          text: "Klient został dodany!",
        });
        fetchClients();
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text: "Błąd podczas dodawania klienta" + JSON.stringify(formData),
        });
      }
    } catch (error) {
      console.error("Błąd:", error);
    }
  };

  const handleUpdateClient = async (e) => {
    e.preventDefault();
    // alert(JSON.stringify(formData));
    try {
      const response = await fetch(
        `/api/klienci/${formData.id}?uzyt_id=${uzyt_id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Sukces",
          text: "Klient został zaktualizowany!",
        });
        fetchClients();
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text: "Błąd podczas aktualizacji klienta",
        });
      }
    } catch (error) {
      console.error("Błąd:", error);
    }
  };

  // Funkcja do sprawdzenia, czy klient ma powiązane prace
  const checkClientHasPrace = async (clientId) => {
    try {
      const response = await fetch(`/api/prace/klient/${clientId}`, {
        method: "GET",
      });
      const data = await response.json();
      return data.length > 0; // Zwraca true, jeśli są powiązane prace
    } catch (error) {
      console.error("Błąd podczas sprawdzania prac dla klienta:", error);
      return false; // W przypadku błędu zakładamy brak prac
    }
  };

  const handleDeleteClient = async (id) => {
    // Sprawdź, czy klient ma powiązane prace
    if (checkPermissions2(6)) {
      const hasPrace = await checkClientHasPrace(id);

      if (hasPrace) {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text: "Nie można usunąć klienta, ponieważ istnieją powiązane prace!",
        });
        return;
      }

      Swal.fire({
        title: "Czy na pewno chcesz usunąć klienta?",
        text: "Tego działania nie można cofnąć!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`/api/klienci/${id}?uzyt_id=${uzyt_id}`, {
            method: "DELETE",
          })
            .then((response) => {
              if (response.ok) {
                Swal.fire({
                  icon: "success",
                  title: "Sukces",
                  text: "Klient został usunięty!",
                });
                fetchClients();
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Błąd",
                  text: "Błąd podczas usuwania klienta",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Błąd",
                text: "Wystąpił problem podczas usuwania klienta.",
              });
            });
        }
      });
    }
  };

  // Funkcja do pobierania danych z API Regon na podstawie NIP
  const handleFetchDataFromRegon = async () => {
    const nip = formData.nip;

    if (!nip) {
      Swal.fire({
        icon: "warning",
        title: "Brak NIP",
        text: "Proszę podać numer NIP.",
      });
      return;
    }

    try {
      // Wyślij żądanie do API z NIP
      const response = await fetch(`/api/regonBir/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ nip: formData.nip }),
      });

      if (response.ok) {
        const regonData = await response.json();

        // Uaktualnij pola formularza na podstawie danych z API
        const updatedData = regonData[0]; // Zakładając, że dane są w tablicy

        setFormData((prevData) => ({
          ...prevData,
          nazwa: updatedData.Nazwa || "",
          ulica: updatedData.Ulica || "",
          kod_pocztowy: updatedData.KodPocztowy || "",
          miasto: updatedData.Miejscowosc || "",
          regon: updatedData.Regon || "",
        }));

        Swal.fire({
          icon: "success",
          title: "Dane zaktualizowane!",
          text: "Dane zostały pobrane i zaktualizowane.",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text:
            "Nie udało się pobrać danych z API Regon. Status: " +
            response.status,
        });
      }
    } catch (error) {
      console.error("Błąd podczas pobierania danych z API Regon:", error);
      Swal.fire({
        icon: "error",
        title: "Błąd",
        text: "Wystąpił błąd podczas komunikacji z API Regon.",
      });
    }
  };

  // Filtruj klientów na podstawie wpisanego wyszukiwanego terminu
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  useEffect(() => {
    const filtered = clients.filter((client) => {
      const matchesNazwa = client.nazwa.toLowerCase().includes(searchTerm);
      const matchesAdres = (
        client.ulica +
        " " +
        client.nr_domu +
        ", " +
        client.kod_pocztowy +
        " " +
        client.miasto
      )
        .toLowerCase()
        .includes(searchTerm);
      const matchesNip = client.nip.includes(searchTerm);
      const matchesTelefon = client.telefon.includes(searchTerm);
      const matchesEmail = client.email.toLowerCase().includes(searchTerm);

      return (
        matchesNazwa ||
        matchesAdres ||
        matchesNip ||
        matchesTelefon ||
        matchesEmail
      );
    });

    setFilteredClients(filtered);
  }, [searchTerm, clients]);

  // Stronicowanie
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClients.slice(
    indexOfFirstClient,
    indexOfLastClient
  );

  const totalPages = Math.ceil(filteredClients.length / clientsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Funkcja do wyświetlania tylko 8 przycisków paginacji
  const renderPaginationButtons = () => {
    const buttons = [];
    const maxButtons = 8; // Ilość przycisków do wyświetlenia
    let startPage = Math.max(1, currentPage - Math.floor(maxButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxButtons - 1);

    if (endPage - startPage < maxButtons - 1) {
      startPage = Math.max(1, endPage - maxButtons + 1);
    }

    // Dodaj przycisk dla pierwszej strony
    if (startPage > 1) {
      buttons.push(
        <button key={1} onClick={() => handlePageChange(1)}>
          1
        </button>
      );
      if (startPage > 2) {
        buttons.push(<span key="ellipsis-start">...</span>);
      }
    }

    // Dodaj środkowe przyciski paginacji
    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={i === currentPage ? "active" : ""}
        >
          {i}
        </button>
      );
    }

    // Dodaj przycisk dla ostatniej strony
    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        buttons.push(<span key="ellipsis-end">...</span>);
      }
      buttons.push(
        <button key={totalPages} onClick={() => handlePageChange(totalPages)}>
          {totalPages}
        </button>
      );
    }

    return buttons;
  };

  return (
    <div className="klienci-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Lista klientów</h2>

        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>

      <button className="add-client" onClick={handleAddClient}>
        <FontAwesomeIcon icon={faPlus} /> Dodaj klienta
      </button>

      {/* Pole wyszukiwania */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Wyszukaj"
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      {/* Tabela klientów */}
      <table>
        <thead>
          <tr>
            <th>Nazwa</th>
            <th>Adres</th>
            <th>NIP</th>
            <th>Email</th>
            <th>Telefon</th>
            {showCenarg && <th>Cena Rg</th>}{" "}
            {/* Warunkowe wyświetlanie kolumny cenarg */}
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {currentClients.map((client) => (
            <tr key={client.id}>
              <td>{client.nazwa}</td>
              <td>
                {client.ulica} {client.nr_domu}, {client.kod_pocztowy}{" "}
                {client.miasto}
              </td>
              <td>{client.nip}</td>
              <td>{client.email}</td>
              <td>{client.telefon}</td>
              {showCenarg && <td>{client.cenarg}</td>}{" "}
              {/* Warunkowe wyświetlanie pola cenarg */}
              <td className="action-buttons">
                <button
                  className="edit"
                  onClick={() => handleEditClient(client)}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </button>
                <button
                  className="delete"
                  onClick={() => handleDeleteClient(client.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Stronicowanie */}
      <div className="pagination">{renderPaginationButtons()}</div>

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h2>{isEditing ? "Edytuj klienta" : "Dodaj klienta"}</h2>
        <form onSubmit={isEditing ? handleUpdateClient : handleAddNewClient}>
          <input
            type="text"
            name="nazwa"
            value={formData.nazwa}
            onChange={handleChange}
            placeholder="Nazwa firmy"
            required
          />
          <div className="form-table">
            <div className="table-row">
              {/* Pierwsza kolumna (z polami dla NIP i reszty pierwszej grupy) */}
              <div className="table-column">
                <input
                  type="text"
                  name="ulica"
                  value={formData.ulica}
                  onChange={handleChange}
                  placeholder="Ulica"
                />
                <input
                  type="text"
                  name="nr_domu"
                  value={formData.nr_domu}
                  onChange={handleChange}
                  placeholder="Nr domu"
                />
                <input
                  type="text"
                  name="kod_pocztowy"
                  value={formData.kod_pocztowy}
                  onChange={handleChange}
                  placeholder="Kod pocztowy"
                />
                <input
                  type="text"
                  name="miasto"
                  value={formData.miasto}
                  onChange={handleChange}
                  placeholder="Miasto"
                />
                <input
                  type="text"
                  name="nip"
                  value={formData.nip}
                  onChange={handleChange}
                  placeholder="NIP"
                  required
                />
                <textarea
                  name="notatki"
                  value={formData.notatki}
                  onChange={handleChange}
                  placeholder="Notatki"
                />
                <input
                  type="text"
                  name="plikwzor"
                  value={formData.plikwzor}
                  onChange={handleChange}
                  placeholder="Prefix pliku "
                  required
                />
              </div>
              {/* Druga kolumna (pozostałe pola) */}
              <div className="table-column">
                <input
                  type="text"
                  name="regon"
                  value={formData.regon}
                  onChange={handleChange}
                  placeholder="REGON"
                />
                <input
                  type="text"
                  name="krs"
                  value={formData.krs}
                  onChange={handleChange}
                  placeholder="KRS"
                />
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
                <input
                  type="text"
                  name="telefon"
                  value={formData.telefon}
                  onChange={handleChange}
                  placeholder="Telefon"
                />
                {checkPermissions3(10) && (
                  <input
                    type="number"
                    step="10.00"
                    name="cenarg"
                    value={formData.cenarg}
                    onChange={handleChange}
                    placeholder="Cena rg"
                  />
                )}
                {checkPermissions3(11) && (
                  <textarea
                    name="opisroz"
                    value={formData.opisroz}
                    onChange={handleChange}
                    placeholder="Notatki rozliczenia"
                  />
                )}
              </div>
            </div>
          </div>

          {/* Przyciski na dole */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              type="button"
              onClick={handleFetchDataFromRegon}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "blue",
              }}
            >
              <FontAwesomeIcon icon={faSync} /> Aktualizuj dane z NIP
            </button>

            <div style={{ flexGrow: 1 }} />
            <button
              type="submit"
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                marginLeft: "10px",
              }}
            >
              Zapisz
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#dc3545",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
                marginLeft: "10px",
              }}
            >
              Anuluj
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default KlienciPage;
