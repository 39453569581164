import React, { useState, useEffect } from "react";
import "./Archiwum.css"; // Import stylu
import Swal from "sweetalert2";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { checkPermissions3, getuser3 } from "./Permission";
import Modal from "./Modal";

import "./PracePage.css";

function PracaEdit({
  idPraca,
  isEditing,
  formDataInit,
  onSave,
  onCancel,
  loggedUser,
}) {
  const [formData, setFormData] = useState({
    filter_klient_id: "",
    klient_id: "",
    klient_nazwa: "",
    klient_szukaj: "",
    uzytkownik_id: loggedUser.email,
    data: new Date().toISOString().split("T")[0],
    data_od: new Date().toISOString().split("T")[0], // Domyślna wartość sprzed 180 dni
    data_do: new Date().toISOString().split("T")[0], // Domyślna wartość sprzed 180 dni
    godzina: new Date().getHours() + new Date().getMinutes() / 60,
    ile_czasu: "",
    ile_czasu2: "",
    opis: "",
    opis2: "",
    opis2c: "",
    trwa: "",
    projekt: "",
    projekt2: "",
    uzytkownik2: loggedUser.email,
    datatdo: new Date().toISOString().split("T")[0],
  });
  const [praca, setPraca] = useState([]);
  const uzyt_id = getuser3();
  const [klienci, setKlienci] = useState([]);

  const [filteredKlienci, setFilteredKlienci] = useState([]);
  const [uzytkownicy, setUzytkownicy] = useState([]);
  const [loadingPraca, setLoadingPraca] = useState(true);
  const [zaladowano, setZaladowano] = useState(false);
  const [zaladowano2, setZaladowano2] = useState(false);
  const handleSearchChange = (e) => {
    const searchTerm = e.target.value;
    setFormData({ ...formData, klient_szukaj: searchTerm });
  };

  const fetchPraca = async (moja) => {
    if (!zaladowano) {
      try {
        setZaladowano(false);
        setLoadingPraca(true); // Ustawienie stanu ładowania na true
        localStorage.setItem("lastActivity", new Date().toISOString());
        const response = await fetch(`/api/praca/${moja}`, {
          method: "GET",
          cache: "no-store",
        });
        const data = await response.json();
        // alert("user32" + data.uzytkownik2);
        // alert("data32" + data.datatdo);
        setFormData({
          id: data.id,
          klient_id: data.klient_id ? data.klient_id.toString() : "",
          klient_nazwa: data.klient_nazwa || "",
          uzytkownik_id: data.uzytkownik_id,
          data: data.data.split("T")[0],
          data_od: data.data_od.split("T")[0],
          data_do: data.data_do.split("T")[0],
          godzina: data.godzina,
          ile_czasu: data.ile_czasu,
          ile_czasu2: data.ile_czasu2,
          opis: data.opis || "",
          opis2: data.opis2 || "",
          opis2c: data.opis2c || "",
          projekt: data.projekt || "",
          projekt2: data.projekt2 || "",
          trwa: data.trwa === "X" ? "X" : " ", // Przypisujemy "X" lub pusty string
          datatdo: data.datatdo
            ? data.datatdo.split("T")[0]
            : new Date().toISOString().split("T")[0], // Użyj bieżącej daty, jeśli datatdo jest null
          uzytkownik2: data.uzytkownik2 ? data.uzytkownik2 : "",
        });
        setLoadingPraca(false); // Ustawienie stanu ładowania na false
        setZaladowano(true);
      } catch (error) {
        console.error("Error fetching praca:", error);
        setLoadingPraca(false);
      }
    }
  };
  const fetchKlienci = async () => {
    try {
      localStorage.setItem("lastActivity", new Date().toISOString());
      const response = await fetch("/api/klienci/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setKlienci(data);
      setFilteredKlienci(data);
    } catch (error) {
      console.error("Error fetching clients:", error);
    }
  };

  const fetchUzytkownicy = async () => {
    try {
      const response = await fetch("/api/uzytkownicy/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setUzytkownicy(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  const validateForm = () => {
    const dateDo = formData.data_do;
    const dateOd = formData.data_od;
    if (new Date(dateDo) < new Date(dateOd)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Data do musi być większa lub równa dacie od",
      });
      return false; // Zatrzymuje wysłanie formularza
    }
    if (new Date(dateDo).getMonth() !== new Date(dateOd).getMonth()) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Data od i data do muszą być w tym samym miesiącu",
      });
      return;
    }

    let regex;
    if (formData.trwa === "X") {
      regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku
    } else {
      regex = /^(?!0$)\d{1,4}(\.\d)?$/; // Akceptuje liczby całkowite do 4 cyfr lub liczby z jedną cyfrą po przecinku, ale nie akceptuje samego "0"
    }

    if (regex.test(formData.ile_czasu2)) {
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Wpis godziny formacie 9999.9 ",
      });
      return false; // Zatrzymuje wysłanie formularza
    }
    if (formData.opis2c === "X") {
      if (regex.test(formData.ile_czasu)) {
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Wpis godziny2 formacie 9999.9 ",
        });
        return false; // Zatrzymuje wysłanie formularza
      }
    }

    // Możesz dodać więcej warunków walidacyjnych
    // np. sprawdzenie, czy inne pola są poprawnie wypełnione

    return true; // Kontrola zakończona powodzeniem
  };

  // Funkcja obsługująca `onSubmit`
  const handleSubmit = (e) => {
    e.preventDefault(); // Zatrzymuje domyślne wysłanie formularza

    if (!validateForm()) {
      return; // Jeśli walidacja nie przeszła, formularz nie zostanie wysłany
    }

    // Jeśli walidacja przeszła, uruchamiamy odpowiednią funkcję
    isEditing ? handleUpdatePraca(e) : handleAddNewPraca(e);
  };

  const handleUpdatePraca = async (e) => {
    e.preventDefault();

    // Tworzymy kopię formData, żeby pracować na niej przed wysłaniem
    const updatedFormData = { ...formData };

    // Sprawdź i uzupełnij domyślne wartości
    if (updatedFormData.opis2c !== "X") {
      updatedFormData.opis = updatedFormData.opis2;
      updatedFormData.ile_czasu = updatedFormData.ile_czasu2;
      updatedFormData.projekt = updatedFormData.projekt2;
    }

    try {
      const response = await fetch(
        `/api/prace/${updatedFormData.id}?uzyt_id=${uzyt_id}`,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(updatedFormData), // Przekazujemy dane z uzupełnionymi wartościami
        }
      );

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Praca została zaktualizowana!",
        });
        onSave();
        // setIsModalOpen(false); // Zamknij modal
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text:
            "Błąd podczas aktualizacji pracy " +
            JSON.stringify(updatedFormData),
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAddNewPraca = async (e) => {
    e.preventDefault();

    // Tworzymy kopię formData, żeby pracować na niej przed wysłaniem
    const newFormData = { ...formData };

    // Sprawdź i uzupełnij domyślne wartości
    if (!newFormData.opis) {
      newFormData.opis = newFormData.opis2;
    }

    if (!newFormData.ile_czasu) {
      newFormData.ile_czasu = newFormData.ile_czasu2;
    }

    if (!newFormData.trwa) {
      newFormData.trwa = " ";
    }

    if (!newFormData.projekt) {
      newFormData.projekt = newFormData.projekt2;
    }

    // Upewniamy się, że formularz został poprawnie uzupełniony i wysyłamy dane
    try {
      const response = await fetch(`/api/prace/?uzyt_id=${uzyt_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(newFormData), // Przekazujemy dane z uzupełnionymi wartościami
      });

      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Praca została dodana!",
        });

        // setIsModalOpen(false); // Zamknij modal
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Błąd podczas dodawania pracy",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //   useEffect(() => {
  if (!zaladowano2) {
    fetchKlienci();
    fetchUzytkownicy();
    // alert("off " + idPraca);
    fetchPraca(idPraca);

    setZaladowano2(true);
  }
  //   });

  return (
    <Modal show={true} onClose={() => onCancel}>
      <div>
        <h2>
          {isEditing ? `Edytuj pracę (ID: ${formData.id})` : "Dodaj pracę"}
        </h2>

        <form
          className="formmodalpraca"
          onSubmit={handleSubmit}
          // onSubmit={isEditing ? handleUpdatePraca : handleAddNewPraca}
        >
          <table>
            <tbody>
              <tr>
                <td className="one-third">
                  <input
                    type="text"
                    placeholder="Wyszukaj klienta (nazwa, NIP, e-mail, telefon)"
                    value={formData.klient_szukaj}
                    onChange={handleSearchChange}
                  />

                  <select
                    name="klient_id"
                    value={formData.klient_id}
                    onChange={(e) => {
                      const selectedKlient = klienci.find(
                        (klient) => klient.id === parseInt(e.target.value, 10)
                      );
                      setFormData({
                        ...formData,
                        klient_id: e.target.value,
                        klient_nazwa: selectedKlient
                          ? selectedKlient.nazwa
                          : "",
                      });
                    }}
                    required
                  >
                    <option value="">Wybierz klienta</option>
                    {klienci.map((klient) => (
                      <option key={klient.id} value={klient.id}>
                        {klient.nazwa} - {klient.nip} - {klient.email} -{" "}
                        {klient.telefon}
                      </option>
                    ))}
                  </select>

                  <select
                    name="uzytkownik_id"
                    value={formData.uzytkownik_id}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        uzytkownik_id: e.target.value,
                      })
                    }
                    required
                  >
                    <option value={loggedUser.email}>
                      {loggedUser.imie} {loggedUser.nazwisko} (Zalogowany)
                    </option>
                    {checkPermissions3(2) &&
                      uzytkownicy.map((uzytkownik) => (
                        <option key={uzytkownik.email} value={uzytkownik.email}>
                          {uzytkownik.imie} {uzytkownik.nazwisko}
                        </option>
                      ))}
                  </select>

                  <input
                    type="date"
                    name="data_od"
                    value={formData.data_od}
                    onChange={(e) => {
                      const dateOd = e.target.value;
                      const dateDo = formData.data_do;

                      // Sprawdzenie, czy data_do jest w tym samym miesiącu co data_od
                      if (
                        dateDo &&
                        new Date(dateOd).getMonth() !==
                          new Date(dateDo).getMonth()
                      ) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Data od i data do muszą być w tym samym miesiącu",
                        });
                        return;
                      }
                      if (dateDo && new Date(dateDo) < new Date(dateOd)) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Data do musi być większa lub równa dacie od",
                        });
                        return;
                      }
                      setFormData({ ...formData, data_od: dateOd });
                    }}
                    required
                  />
                  <input
                    type="date"
                    name="data_do"
                    value={formData.data_do}
                    onChange={(e) => {
                      const dateDo = e.target.value;
                      const dateOd = formData.data_od;

                      if (
                        dateOd &&
                        new Date(dateDo).getMonth() !==
                          new Date(dateOd).getMonth()
                      ) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Data od i data do muszą być w tym samym miesiącu",
                        });
                        return;
                      }

                      if (dateOd && new Date(dateDo) < new Date(dateOd)) {
                        Swal.fire({
                          icon: "error",
                          title: "Error",
                          text: "Data do musi być większa lub równa dacie od",
                        });
                        return;
                      }
                      setFormData({ ...formData, data_do: dateDo });
                    }}
                    required
                  />

                  <textarea
                    name="opis2"
                    value={formData.opis2}
                    onChange={(e) =>
                      setFormData({ ...formData, opis2: e.target.value })
                    }
                    placeholder="Opis pracy"
                  />

                  <input
                    type="number"
                    name="ile_czasu2"
                    value={formData.ile_czasu2}
                    onChange={(e) =>
                      setFormData({ ...formData, ile_czasu2: e.target.value })
                    }
                    // onChange={(e) => {
                    //   const value = e.target.value;
                    //   let regex;

                    //   regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku

                    //   if (regex.test(value)) {
                    //     setFormData({ ...formData, ile_czasu2: value });
                    //   } else {
                    //     Swal.fire({
                    //       icon: "error",
                    //       title: "Error",
                    //       text: "Wpis tylko w formacie 9999.9 ",
                    //     });
                    //     return;
                    //   }
                    // }}
                    placeholder="Czas (np. 1.25)"
                    required
                  />

                  <input
                    type="text"
                    name="projekt2"
                    value={formData.projekt2}
                    onChange={(e) =>
                      setFormData({ ...formData, projekt2: e.target.value })
                    }
                    placeholder="Projekt do grupowania"
                  />

                  <select
                    name="uzytkownik2"
                    value={formData.uzytkownik2 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, uzytkownik2: e.target.value })
                    }
                  >
                    {/* Default selectable option for "no selection" */}
                    <option value="">Wybierz współautora</option>

                    {/* Render user options */}
                    {uzytkownicy.map((uzytkownik) => (
                      <option key={uzytkownik.email} value={uzytkownik.email}>
                        {uzytkownik.imie} {uzytkownik.nazwisko}
                      </option>
                    ))}
                  </select>
                </td>

                {checkPermissions3(12) && (
                  <td className="one-third">
                    {checkPermissions3(12) && (
                      <input
                        type="number"
                        name="ile_czasu"
                        value={formData.ile_czasu}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            ile_czasu: e.target.value,
                          })
                        }
                        // onChange={(e) => {
                        //   const value = e.target.value;
                        //   let regex;

                        //   regex = /^(0|\d{1,4})(\.\d)?$/; // Akceptuje "0", liczbę całkowitą do 4 cyfr lub liczbę z jedną cyfrą po przecinku

                        //   if (regex.test(value)) {
                        //     setFormData({ ...formData, ile_czasu: value });
                        //   } else {
                        //     Swal.fire({
                        //       icon: "error",
                        //       title: "Error",
                        //       text: "Wpis tylko w formacie 9999.9 ",
                        //     });
                        //     return;
                        //   }
                        // }}
                        placeholder="Czas rozliczeniowy"
                      />
                    )}

                    {checkPermissions3(12) && (
                      <input
                        type="text"
                        name="projekt"
                        value={formData.projekt}
                        onChange={(e) =>
                          setFormData({ ...formData, projekt: e.target.value })
                        }
                        placeholder="Projekt rozliczeniowy"
                      />
                    )}

                    {checkPermissions3(12) && (
                      <textarea
                        name="opis"
                        value={formData.opis}
                        onChange={(e) =>
                          setFormData({ ...formData, opis: e.target.value })
                        }
                        placeholder="Opis rozliczeniowy"
                      />
                    )}

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <label
                        style={{
                          fontSize: "18px",
                          display: "inline-flex",
                          alignItems: "center",
                          marginRight: "10px",
                        }}
                      >
                        Opisy i czas rozliczeniowe 2
                      </label>
                      <input
                        type="checkbox"
                        style={{
                          width: "30px",
                          height: "30px",
                          marginRight: "20px",
                        }}
                        checked={formData.opis2c === "X"}
                        onChange={(e) => {
                          setFormData({
                            ...formData,
                            opis2c: e.target.checked ? "X" : " ",
                          });
                        }}
                      />
                    </div>
                  </td>
                )}
              </tr>
            </tbody>
          </table>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "20px",
            }}
          >
            <label
              style={{
                fontSize: "18px",
                display: "inline-flex",
                alignItems: "center",
              }}
            >
              Praca trwa
            </label>
            <input
              type="checkbox"
              style={{ width: "30px", height: "30px", marginRight: "10px" }}
              checked={formData.trwa === "X"}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  trwa: e.target.checked ? "X" : " ",
                });
              }}
            />

            {formData.trwa === "X" && (
              <label
                style={{
                  fontSize: "18px",
                  display: "inline-flex",
                  alignItems: "center",
                  marginLeft: "10px",
                }}
              >
                Termin wykonania
              </label>
            )}
            {formData.trwa === "X" && (
              <input
                type="date"
                name="datatdo"
                value={formData.datatdo}
                onChange={(e) =>
                  setFormData({ ...formData, datatdo: e.target.value })
                }
                placeholder="Planowana data wykonania"
                style={{ width: "200px", marginLeft: "10px" }}
              />
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              type="submit"
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Zapisz
            </button>
            <button
              type="button"
              onClick={onCancel}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#dc3545",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Anuluj
            </button>
          </div>
        </form>
      </div>
    </Modal>
  );
}

export default PracaEdit;
