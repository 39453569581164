import React, { useState, useEffect } from "react";
import Modal from "./ModalUser"; // Import okna modalnego
import "./UserPage.css"; // Import stylu dla user-page
import Swal from "sweetalert2"; // Import Swal
import "@fortawesome/fontawesome-free/css/all.min.css"; // Import Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // Import FontAwesomeIcon
import { faTimes } from "@fortawesome/free-solid-svg-icons"; // Import ikon
import { checkPermissions2, getuser3 } from "./Permission";

const UserPage = ({ onClose }) => {
  const [users, setUsers] = useState([]);
  const [formData, setFormData] = useState({
    email: "",
    nazwisko: "",
    imie: "",
    telefon: "",
    haslo: "",
    uprawnienia: "0".repeat(20),
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const uzyt_id = getuser3();
  const [isEditing, setIsEditing] = useState(false);

  const permissions = [
    { index: 0, label: "Prace\u202Fodczyt" },
    { index: 1, label: "Prace\u202Fzapis" },
    { index: 2, label: "Prace\u202Fedycja\u202Fnie swoich" },
    { index: 12, label: "Prace\u202Fedycja\u202Fprzed\u202Fraportem" },
    { index: 13, label: "Prace\u202Ftrwające" },
    { index: 16, label: "Prace\u202Fwydruk\u202Fpdf" },
    { index: 3, label: "Użytkownicy\u202Fodczyt" },
    { index: 4, label: "Użytkownicy\u202Fzapis" },
    { index: 5, label: "Firmy\u202Fodczyt" },
    { index: 6, label: "Firmy\u202Fzapis" },
    { index: 10, label: "Firmy\u202Fstawki\u202Ffirm" },
    { index: 11, label: "Opis\u202Frozliczenia\u202Ffirm" },

    { index: 7, label: "Zestawienia\u202Fwłasne" },
    { index: 8, label: "Zestawienia\u202Fobce" },
    { index: 9, label: "Zestawienia\u202Fszczegółowe" },
    { index: 14, label: "Raport\u202Fprac\u202Fw\u202Ftoku" },
    { index: 15, label: "Archiwum zmian" },
  ];
  const half = Math.ceil(permissions.length / 2);
  // Funkcja do pobierania użytkowników z API
  const fetchUsers = async () => {
    try {
      localStorage.setItem("lastActivity", new Date().toISOString());
      const response = await fetch("/api/uzytkownicy/", {
        method: "GET",
        cache: "no-store",
      });
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Błąd pobierania danych:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handlePermissionChange = (index, checked) => {
    let updatedPermissions = formData.uprawnienia.split("");
    updatedPermissions[index] = checked ? "1" : "0";
    setFormData((prevData) => ({
      ...prevData,
      uprawnienia: updatedPermissions.join(""),
    }));
  };

  const handleEditUser = (user) => {
    if (checkPermissions2(4)) {
      setIsEditing(true);
      setFormData({
        ...user,
        uprawnienia: user.uprawnienia.padEnd(20, "0"),
      });
      setIsModalOpen(true);
    }
  };

  const handleAddUser = () => {
    if (checkPermissions2(4)) {
      setIsEditing(false);
      setFormData({
        email: "",
        nazwisko: "",
        imie: "",
        telefon: "",
        haslo: "",
        uprawnienia: "0".repeat(20),
      });
      setIsModalOpen(true);
    }
  };

  const handleAddNewUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`/api/uzytkownicy/?uzyt_id=${uzyt_id}`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Sukces",
          text: "Użytkownik został dodany!",
        });
        fetchUsers();
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text: "Błąd podczas dodawania użytkownika",
        });
      }
    } catch (error) {
      console.error("Błąd:", error);
    }
  };

  const handleUpdateUser = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `/api/uzytkownicy/${formData.email}?uzyt_id=${uzyt_id} `,
        {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      if (response.ok) {
        Swal.fire({
          icon: "success",
          title: "Sukces",
          text: "Użytkownik został zaktualizowany!",
        });
        fetchUsers();
        setIsModalOpen(false);
      } else {
        Swal.fire({
          icon: "error",
          title: "Błąd",
          text: "Błąd podczas aktualizacji użytkownika" + response.status,
        });
      }
    } catch (error) {
      console.error("Błąd:", error);
    }
  };

  const handleDeleteUser = async (email) => {
    if (checkPermissions2(4)) {
      Swal.fire({
        title: "Czy na pewno chcesz usunąć użytkownika?",
        text: "Tego działania nie można cofnąć!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Tak, usuń",
        cancelButtonText: "Anuluj",
      }).then((result) => {
        if (result.isConfirmed) {
          fetch(`/api/uzytkownicy/${email}?uzyt_id=${uzyt_id}`, {
            method: "DELETE",
          })
            .then((response) => {
              if (response.ok) {
                Swal.fire({
                  icon: "success",
                  title: "Sukces",
                  text: "Użytkownik został usunięty!",
                });
                fetchUsers(); // Odśwież listę użytkowników po usunięciu
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Błąd",
                  text: "Błąd podczas usuwania użytkownika",
                });
              }
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Błąd",
                text: "Wystąpił problem podczas usuwania użytkownika.",
              });
            });
        }
      });
    }
  };

  return (
    <div className="user-page">
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h2>Lista użytkowników</h2>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={onClose}
          style={{ cursor: "pointer", fontSize: "20px" }}
        />
      </div>
      <button className="add-user" onClick={handleAddUser}>
        <i className="fas fa-user-plus"></i> Dodaj użytkownika
      </button>

      <table>
        <thead>
          <tr>
            <th>Email</th>
            <th>Nazwisko</th>
            <th>Imię</th>
            <th>Telefon</th>
            <th>Uprawnienia</th>
            <th>Akcje</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.email}>
              <td>{user.email}</td>
              <td>{user.nazwisko}</td>
              <td>{user.imie}</td>
              <td>{user.telefon}</td>
              <td>{user.uprawnienia.substring(0, 9)}</td>
              <td className="action-buttons">
                <button className="edit" onClick={() => handleEditUser(user)}>
                  <i className="fas fa-edit"></i>
                </button>
                <button
                  className="delete"
                  onClick={() => handleDeleteUser(user.email)}
                >
                  <i className="fas fa-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <Modal show={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <h4>{isEditing ? "Edytuj użytkownika" : "Dodaj użytkownika"}</h4>
        <form onSubmit={isEditing ? handleUpdateUser : handleAddNewUser}>
          <input
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Email"
            required
            autocomplete="off"
            disabled={isEditing}
            style={{ marginBottom: "2px" }} // Zmniejszenie marginesu
          />
          <input
            type="text"
            name="imie"
            value={formData.imie}
            onChange={handleChange}
            placeholder="Imię"
            required
            style={{ marginBottom: "2px" }} // Zmniejszenie marginesu
          />
          <input
            type="text"
            name="nazwisko"
            value={formData.nazwisko}
            onChange={handleChange}
            placeholder="Nazwisko"
            required
            style={{ marginBottom: "2px", marginTop: "2px" }} // Zmniejszenie marginesu
          />

          <input
            type="text"
            name="telefon"
            value={formData.telefon}
            onChange={handleChange}
            placeholder="Telefon"
            style={{ marginBottom: "2px", marginTop: "2px" }} // Zmniejszenie marginesu
          />
          <input
            type="password"
            name="haslo"
            value={formData.haslo}
            onChange={handleChange}
            placeholder="Hasło"
            required
            autocomplete="new-password"
            style={{ marginBottom: "8px" }} // Zmniejszenie marginesu
          />

          <fieldset>
            <legend>Uprawnienia:</legend>

            <table style={{ width: "100%", borderCollapse: "collapse" }}>
              <tbody>
                <tr>
                  {/* Pierwsza kolumna */}
                  <td style={{ verticalAlign: "top", width: "50%" }}>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        {permissions.slice(0, half).map((perm) => (
                          <tr
                            key={perm.index}
                            style={{ borderBottom: "1px solid #ccc" }}
                          >
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "left",
                                width: "80%",
                              }}
                            >
                              {perm.label}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "right",
                                width: "20%",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  formData.uprawnienia[perm.index] === "1"
                                }
                                onChange={(e) =>
                                  handlePermissionChange(
                                    perm.index,
                                    e.target.checked
                                  )
                                }
                                aria-label={perm.label}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>

                  {/* Druga kolumna */}
                  <td style={{ verticalAlign: "top", width: "50%" }}>
                    <table
                      style={{ width: "100%", borderCollapse: "collapse" }}
                    >
                      <tbody>
                        {permissions.slice(half).map((perm) => (
                          <tr
                            key={perm.index}
                            style={{ borderBottom: "1px solid #ccc" }}
                          >
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "left",
                                width: "80%",
                              }}
                            >
                              {perm.label}
                            </td>
                            <td
                              style={{
                                padding: "8px",
                                textAlign: "right",
                                width: "20%",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={
                                  formData.uprawnienia[perm.index] === "1"
                                }
                                onChange={(e) =>
                                  handlePermissionChange(
                                    perm.index,
                                    e.target.checked
                                  )
                                }
                                aria-label={perm.label}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>
          </fieldset>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            <button
              type="submit"
              style={{ padding: "10px 20px", fontSize: "16px" }}
            >
              Zapisz zmiany
            </button>
            <button
              type="button"
              onClick={() => setIsModalOpen(false)}
              style={{
                padding: "10px 20px",
                fontSize: "16px",
                backgroundColor: "#dc3545",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Odrzuć zmiany
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default UserPage;
