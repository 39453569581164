import React from "react";
import "./Modal.css"; // Styl dla modal
import { checkPermissions3 } from "./Permission";
const Modal = ({ show, onClose, children }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div
        className="modal-content2"
        style={{
          width: checkPermissions3(12) ? "1200px" : "800px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/*  <button className="modal-close" onClick={onClose}>
            &times;
          </button> */}
        </div>
        {children}{" "}
        {/* Przycisk "Zapisz zmiany" jest już w formularzu, więc nie ruszamy go tutaj */}
      </div>
    </div>
  );
};

export default Modal;
